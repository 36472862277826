<template>
  <div class="row bg-white">
    <div v-if="!isLoad" class="col-12">
      <div v-if="materials != null" class="row">
        <div class="col-12 text-center py-4">
          <video class="w-75 h-auto" controls>
            <source :src=" materials.path" type="video/mp4">
          </video>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 text-center py-3">
          <img :src="'/images/empty.png'" alt="" width="300">
          <h1 class="text-muted">Data don't exist</h1>
        </div>
      </div>
    </div>
    <div v-else class="col-12 text-center py-3">
      <div class="my-3">
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning mx-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      isLoad: true,
      paramsId: this.$route.params.idCourse,
      idMaterial: this.$route.params.idFile,
      media: process.env.VUE_APP_URL_CLOUD,
      materials: null,
    }
  },
  created() {
    this.getMaterials();
  },
  methods: {
    async getMaterials(){
      await axios.get(`${process.env.VUE_APP_URL_API}/teacher/material/detail?slug_course=${this.paramsId}&id=${this.idMaterial}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        }
      }).then(res => {
        this.isLoad = false
        this.materials = res.data
      }).catch((err) => console.error(err.response))
    },
  }
}
</script>